import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './SharedComponents/admin/admin.component';

const routes: Routes = [
  // { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [GoLiveGuard,] },
  {
    path: '', loadChildren: () => import('./modules/core/core.module').then(m => m.CoreModule),

  },
  // {
  //   path: 'coming-soon', loadChildren: () => import('./modules/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),

  // },
  { path: 'admin', component: AdminComponent},
  { path: '*', redirectTo: '' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100] // [x, y]
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
