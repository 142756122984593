import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'loyaltic-header-comingsoon',
  templateUrl: './header-comingsoon.component.html',
  styleUrls: ['./header-comingsoon.component.scss']
})
export class HeaderComingsoonComponent implements OnInit {

  constructor(
    @Inject('ImagePath') public ImagePath
  ) { }

  ngOnInit() {
  }
}
