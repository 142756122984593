import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Loyaltic } from '../constants/api-url.constants';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private http: HttpClient) { }

  signUp(userName) {
    return this.http.post(environment.baseUrl + Loyaltic.API.CaptureEmail, JSON.stringify({ Email: userName }))
      .toPromise().then(response => {
        return response;
      });
  }

  getLiveConfig() {
    return this.http.get(environment.baseUrl + Loyaltic.API.LiveConfig)
      .toPromise().then(response => {
        return response;
      });
  }


  emailReminder(body) {
    return this.http.post(environment.baseUrl + Loyaltic.API.Emailreminder, JSON.stringify(body)).toPromise().then(response => {
      return response;
    });
  }

  logIn(email) {
    return this.http.post(environment.baseUrl + Loyaltic.API.Login,
      JSON.stringify({ Email: email, HasOptedForMarkettingEmail: true }))
      .toPromise().then(response => {
        return response;
      });
  }

  upLoadReceipt(payload) {
    return this.http.post(environment.baseUrl + Loyaltic.API.Upload,
      payload)
      .toPromise().then(response => {
        return response;
      });
  }

  upLoadReceiptWithRealTimeScanning(payload) {
    return this.http.post(environment.baseUrl + Loyaltic.API.UploadWithRealTimeScanning,
      payload)
      .toPromise().then(response => {
        return response;
      });
  }

  RegUploadWithRealTimeScanning(payload) {
    return this.http.post(environment.baseUrl + Loyaltic.API.RegUploadWithRealTimeScanning,
      payload)
      .toPromise().then(response => {
        return response;
      });
  }

  loginWithUploadReceipt(payload) {
    return this.http.post(environment.baseUrl + Loyaltic.API.LoginWithUploadReceipt,
      payload)
      .toPromise().then(response => {
        return response;
      });
  }

  getAvailableGames(userId) {
    return this.http.get(environment.baseUrl + Loyaltic.API.AvailableGames + '?userid=' + userId).toPromise().then(response => {
      return response;
    });
  }

  getAvailableRewards(culture) {
    return this.http.get(environment.baseUrl + Loyaltic.API.AvailableRewards + '?culture=' + culture).toPromise().then(response => {
      return response;
    });
  }

  getCategories(culture) {
    return this.http.get(environment.baseUrl + Loyaltic.API.GetCategories + '?culture=' + culture).toPromise().then(response => {
      return response;
    });
  }

  getProducts(culture) {
    return this.http.get(environment.baseUrl + Loyaltic.API.GetProducts + '?culture=' + culture).toPromise().then(response => {
      return response;
    });
  }

  Getcodedetails(userId, rewardCode) {
    return this.http.get(environment.baseUrl + Loyaltic.API.Getcodedetails + '?userid=' + userId + '&rewardCode=' + rewardCode).toPromise().then(response => {
      return response;
    });
  }

  getRewardsCount() {
    return this.http.get(environment.baseUrl + Loyaltic.API.Awardsredeemed).toPromise().then(response => {
      return response;
    });
  }


  play(userId, isApproved) {
    return this.http.post(environment.baseUrl + Loyaltic.API.Play, JSON.stringify({ UserId: userId, Isapproved: isApproved }))
      .toPromise().then(response => {
        return response;
      });
  }

  captureAddress(body) {
    return this.http.post(environment.baseUrl + Loyaltic.API.CaptureAddress, JSON.stringify(body))
      .toPromise().then(response => {
        return response;
      });
  }

  captureWinEmail(body) {
    return this.http.post(environment.baseUrl + Loyaltic.API.CaptureWinEmail, JSON.stringify(body))
      .toPromise().then(response => {
        return response;
      });
  }

  Markcodeasredeemed(body) {
    return this.http.post(environment.baseUrl + Loyaltic.API.Markcodeasredeemed, JSON.stringify(body))
      .toPromise().then(response => {
        return response;
      });
  }


  CaptureWinMobile(body) {
    return this.http.post(environment.baseUrl + Loyaltic.API.CaptureWinMobile, JSON.stringify(body))
      .toPromise().then(response => {
        return response;
      });
  }


  confirmReceiptUpload(partitionKey, rowKey) {
    return this.http.post(environment.baseUrl +
      Loyaltic.API.confirmuploadafterscanning + '?PartitionKey=' + partitionKey + '&RowKey=' + rowKey, null)
      .toPromise().then(response => {
        return response;
      });
  }

}
