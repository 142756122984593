import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'loyaltic-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  router: Router;

  constructor( router: Router) { 
    this.router = router;
  }

  ngOnInit() {
  }

}
