import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loader = new EventEmitter<any>();
  isShowLoader = true;


  constructor() { }

  show() {
    setTimeout((() => this.loader.emit(true)), 50);
  }

  hide() {
    setTimeout((() => this.loader.emit(false)), 50);
  }

  getInputChangeLoaderEmitter() {
    return this.loader;
  }

}
