import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'loyaltic-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalDialogComponent implements OnInit {

  @Input() modalId: string = 'modal-dialog';

  constructor() { }

  ngOnInit() {
  }

}
