import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './helpers/services/loader.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GaService } from './helpers/services/ga.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalizationService } from './helpers/services/localization.service';
import { Adal8Service } from 'adal-angular8';
import { environment } from 'src/environments/environment';
import { CoreService } from './helpers/services/core.service';
import { AuthService } from './helpers/services/auth.service';
import { Location, DOCUMENT } from '@angular/common';

declare global {
  interface Window {
    //gtag: (...args: any[]) => void
    dataLayer: Record<string, any>
  }
}

@Component({
  selector: 'loyaltic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'loyaltic';
  public loading = false;
  currentLang = 'en';
  showLocalizationPopup = false;
  showSettingIcon = false;
  cultureParam: any;
  isLiveUrl = false;
  date = new Date();
  culture:any;
  // isComingSoon = true;
  // lockview = true;
  // make isComingSoon & lockview  true while using coming-soon logic
  isComingSoon = false;
  lockview = false;

  cookieScriptUrl : any;


  @ViewChild('localizationPopup', { static: false }) localizationPopupRef: any;
  public modalRef: BsModalRef;
  popupConfig = {
    ignoreBackdropClick: true,
    class: 'lyltc win modal-md modal-dialog-left',
  };

  constructor(public translate: TranslateService, private loader: LoaderService, private router: Router,
    private modalService: BsModalService, private localizationService: LocalizationService,
    public gaService: GaService, private adalService: Adal8Service, private activatedRoute: ActivatedRoute,
    private coreService: CoreService, private authService: AuthService,
    private location: Location,
    @Inject(DOCUMENT) private doc: any
    ) {
    this.checkComingSoon()
    translate.addLangs(['da-DK', 'fi-FI', 'sv-SE','nb-NO', 'en-US']);
    
    var currLanguageVal = localStorage.getItem('currLanguage');
    

    if (window.location.href.includes('se')) {
      translate.setDefaultLang('sv-SE');
      localStorage.setItem('currLanguage', 'sv-SE');
    } else if (window.location.href.includes('dk')) {
      translate.setDefaultLang('da-DK');
      localStorage.setItem('currLanguage', 'da-DK');
    } else if(window.location.href.includes('fi')) {
      translate.setDefaultLang('fi-FI');
      localStorage.setItem('currLanguage', 'fi-FI');
    } else if(window.location.href.includes('no')) {
      translate.setDefaultLang('nb-NO');
      localStorage.setItem('currLanguage', 'nb-NO');
    } else {
      translate.setDefaultLang('sv-SE');
      localStorage.setItem('currLanguage', 'sv-SE');
    }
    
    // if (window.location.href.indexOf('https://findano21-se.azurewebsites.net/') > -1 || window.location.href.indexOf('activiakampanj.se') > -1) {
    //   translate.setDefaultLang('sv-SE');
    //   localStorage.setItem('currLanguage', 'sv-SE');
    // } else {
    //   translate.setDefaultLang('fi-FI');
    //   localStorage.setItem('currLanguage', 'fi-FI');
    // }

    //adalService.init(environment.authConfig);

    this.translate.get('cookie-script-url').subscribe((translatedString) => {
        this.cookieScriptUrl = translatedString;
        this.setGTagManager(currLanguageVal);
        //this.cookieScript(currLanguageVal, this.cookieScriptUrl);
        //console.log(this.gaScript1);
    });

  }


  ngOnInit() {
    var currLanguageVal = 'ooppp';
    //this.loadScript()
    //this.adalService.handleWindowCallback();
    // this.cultureParam = window.location.pathname;

    this.adalService.getUser().subscribe(res => {
      if (res) {
        this.showSettingIcon = true;
      } else {
        this.showSettingIcon = false;
      }
    });

    this.loader.getInputChangeLoaderEmitter().subscribe((res: any) => {
      this.loading = res;
    });

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'virtualPageview',
              virtualPage : item.url
          };
          
         window && window.dataLayer && window.dataLayer.push(gtmTag);
        
      }
    });
  }


  // for cookie script
  private cookieScript(cookieLang: string, cookieSrc: any) {

    switch(cookieLang) { 
      case 'sv-SE': { 
         cookieUrl = cookieSrc; // default sv-SE
         break; 
      } 
      case 'nb-NO': { 
        var cookieUrl = cookieSrc;
         break; 
      } 
      case 'da-DK': { 
         cookieUrl = cookieSrc;
         break; 
      } 

      case 'fi-FI': {
        cookieUrl = cookieSrc;
        break;
      }

      default: { 
         cookieUrl = '';
         break; 
      } 
    } 

    const cookieScript = this.doc.createElement('script');
    cookieScript.type = 'text/javascript';
    cookieScript.charset = 'UTF-8';
    cookieScript.src = cookieUrl;
    const body = this.doc.getElementsByTagName('body')[0];
    body.appendChild(cookieScript);
  }


  // for gtm script
  private setGTagManager(currentLang: string) {
    const gtm = this.doc.createElement('script');
    gtm.type = 'text/javascript';
    gtm.innerHTML = `window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'pageType': 'CashbackCampaignPage', 'pageLanguage':'`+  currentLang +`', 'universalPropertyId':'UA-173935033-5', 'GA4PropertyId':'G-R2KGZXSE3T'});
    
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5V38XH');
    
    `;
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(gtm);
  }

  changeLanguage(key: string) {
    this.translate.setDefaultLang(key);
    this.translate.getTranslation(key).subscribe(translation => {
      this.translate.setTranslation(key, translation, true);
      this.translate.use(key);
    });
  }

  showLocalizations() {
    this.showLocalizationPopup = true;
    this.modalRef = this.modalService.show(this.localizationPopupRef, this.popupConfig);
  }

  async checkComingSoon() {
    const hostname = window.location.hostname;
    // Api call for golive
    // let response = await this.liveconfig();
    await this.coreService.getLiveConfig().then((response: any) => {
      console.log("response", response)
      const goLiveDateString = response.result.liveDate;
      const goLiveDate = new Date(goLiveDateString)
      console.log("goLiveDate", goLiveDate)
      response.result.liveUrls.forEach((element: string) => {
        if (hostname.toLowerCase().indexOf(element) != -1) {
          this.isLiveUrl = true;
        }

      });
      if (goLiveDate > this.date && this.isLiveUrl) {
        console.log("show comin soon page")
        this.lockview = false
        this.isComingSoon = true;
        // this.router.navigate(['coming-soon'])
      } else {
        this.lockview = false
        this.isComingSoon = false;
      }

    });
  }
  loadScript() {
    this.translate.stream('GA-script-1').subscribe(e => {
      const script = e
      script != 'GA-script-1' ? this.appendElements(script) : ''
    })

    this.translate.stream('GA-script-2').subscribe(e => {
      const script = e
      script != 'GA-script-2' ? this.appendElements(script) : ''
    })

    this.translate.stream('GTM-script-1').subscribe(e => {
      const script = e
      script != 'GTM-script-1' ? this.appendElements(script) : ''

    })

    this.translate.stream('GTM-script-2').subscribe(e => {
      const script = e
      if (script != 'GTM-script-2') {
        this.appendNoScriptElements(script)
      }
    })

    this.translate.stream('cookie-script-1').subscribe(e => {
      if (e != null && e != 'cookie-script-1') {
        const script = e
        this.appendElements(script)
      }

    })

    this.translate.stream('Meta-tags').subscribe(e => {

      const script = e
      if (script != 'Meta-tags') {
        let scriptArray = script.split("/>")
        scriptArray.forEach((element: string) => {
          if (element.includes("viewport")) {
            console.log("check content->", element.split('content=')[1])
            this.appendMetaTags("viewport", element.split('content=')[1]);
          }

          else if (element.includes("og:")) {
            let propertyName = ''
            if (element.includes("og:url")) {
              propertyName = "og:url"
            } if (element.includes("og:title") && element.includes("property")) {
              propertyName = "og:title"
            } if (element.includes("og:description")) {
              propertyName = "og:description"
            } if (element.includes("og:image")) {
              propertyName = "og:image"
            }
            this.appendOGMetaTags(propertyName, element.split('content=')[1]);
          }
          if (element.includes("description") && element.includes("name")) {
            this.appendMetaTags("description", element.split('content=')[1]);
          }
          if (element.includes("<title>") && element.includes("</title>")) {
            let pre = element.split('</')[0]
            let post = pre.split('>')[1]
            document.title = post
          }
        });
      }
    })
  }

  appendOGMetaTags(propertyName: string, content: string) {
    var js = document.createElement("script");
    document.querySelector('meta[property=' + '"' + propertyName + '"' + ']').setAttribute("content", content);
    //  document.head.appendChild(scriptElemen);
    document.head.appendChild(js);
  }

  appendMetaTags(propertyName: string, content: string) {
    var js = document.createElement("script");
    document.querySelector('meta[name=' + propertyName + ']').setAttribute("content", content);
    //  document.head.appendChild(scriptElemen);
    document.head.appendChild(js);
  }

  appendElements(script: string) {
    let wrapperDi = document.createElement("div");
    let scriptElemen = document.createElement("script");
    wrapperDi.innerHTML = script
    scriptElemen.innerHTML = wrapperDi.firstElementChild.innerHTML;

    for (let i = 0; i < wrapperDi.firstElementChild.attributes.length; i++) {
      const attr = wrapperDi.firstElementChild.attributes[i];
      scriptElemen.setAttribute(attr.name, attr.value);
      console.log("scriptElemen in for-->", scriptElemen)
    }
    document.head.appendChild(scriptElemen);
  }

  appendNoScriptElements(script: string) {
    let wrapperDi = document.createElement("div");
    let scriptElemen = document.createElement("noscript");
    wrapperDi.innerHTML = script
    scriptElemen.innerHTML = wrapperDi.firstElementChild.innerHTML;

    // for (let i = 0; i < wrapperDi.firstElementChild.attributes.length; i++) {
    //   const attr = wrapperDi.firstElementChild.attributes[i];
    //   scriptElemen.setAttribute(attr.name, attr.value);
    //   console.log("NoscriptElement in for-->",scriptElemen)
    // }
    // document.head.appendChild(scriptElemen);
    document.body.appendChild(scriptElemen);
  }
}
