import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Loyaltic } from '../constants/api-url.constants';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  currentPage = '';

  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        console.log(e);
        this.currentPage = e.urlAfterRedirects;
      }
    });
  }

  get(page, culture) {
    return this.http.get(environment.baseUrl + Loyaltic.API.LocalizationByPage +
      '?page=' + page + '&culture=' + culture)
      .toPromise().then(res => {
        return res;
      });
  }

  post(body) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(environment.baseUrl + Loyaltic.API.Localization.Controller +
      Loyaltic.API.Localization.Actions.AddNewGlobalLocalization
      , JSON.stringify(body))
      .toPromise().then(res => {
        return res;
      });
  }

  put(body) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(environment.baseUrl + Loyaltic.API.Localization.Controller +
      Loyaltic.API.Localization.Actions.UpdateGlobalLocalization, JSON.stringify(body), { headers: headers })
      .toPromise().then(res => {
        return res;
      });
  }

  getTranslations(lang) {
    return this.http.get(environment.baseUrl +
      Loyaltic.API.LocalizationByCulture + '?culture=' + lang).toPromise().then((response: any) => {
        const translation = {};
        response.forEach(element => {
          translation[element.key] = element.text;
        });
        return translation;
      });
  }

  reloadTheTranslations(selectedCulture, page) {
    this.get(page, selectedCulture).then((response: any) => {
      const translation = {};
      response.forEach(element => {
        translation[element.key] = element.text;
      });
      this.translate.setTranslation(selectedCulture, translation, true);
      this.translate.use(selectedCulture);
    });
  }

}
