export const Loyaltic = {
    API: {
        CaptureEmail: 'api/v1/capturereminderemail',
        LiveConfig: 'api/v1/liveconfig',
        Emailreminder: 'api/v1/capturereminderemail',
        Login: 'api/v1/login',
        Upload: 'api/v1/user/upload',
        AvailableGames: 'api/v1/user/availablegames',
        AvailableRewards: 'api/v1/user/awardsredeemed',
        GetCategories: 'api/v1/productcategories',
        GetProducts: 'api/v1/products',
        Awardsredeemed: 'api/v1/user/awardsredeemed',
        Getcodedetails: 'api/v1/user/getcodedetails',
        LoginWithUploadReceipt: 'api/v1/user/regwithupload',
        Play: 'api/v1/user/play',
        CaptureAddress: 'api/v1/captureaddress',
        CaptureWinEmail: 'api/v1/capturewinneremail',
        CaptureWinMobile: 'api/v1/capturewinnermobile',
        Markcodeasredeemed: 'api/v1/markcodeasredeemed',
        RegUploadWithRealTimeScanning: 'api/v1/user/regwithrealtimescanningandupload',
        UploadWithRealTimeScanning: 'api/v1/user/uploadwithrealtimescanning',
        confirmuploadafterscanning: 'api/v1/user/confirmuploadafterscanning',
        LocalizationByCulture: 'api/v1/LocalizationByCulture',
        LocalizationByPage: 'api/v1/LocalizationByPage',
        SendFeedback: 'api/v1/sendfeedback',
        UserRewardMarking: 'api/v1/userrewardmarking',
        Localization: {
            Controller: 'Admin/',
            Actions: {
                LocalizationByPage: 'LocalizationByPage',
                AddNewGlobalLocalization: 'AddNewGlobalLocalization',
                UpdateGlobalLocalization: 'UpdateGlobalLocalization',
                LocalizationByCulture: 'LocalizationByCulture',
            }
        }
    },
    WithOutReceiptRedirectUrl: 'https://www.paulig.fi/kahvit/presidentti/suomalaiset-ikonit'
};
