
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/helpers/services/auth.service';

@Component({
  selector: 'loyaltic-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navElement: HTMLElement;

  isDrawerOpen: boolean;
  isLogedIn = false;
  currentPage = '';
  isCollapsed = false;
  //isLoggedIn = false;
  userId ;

  constructor(private router: Router, public authService: AuthService) {
    //this.isLoggedIn = this.authService.IsLoggedIn();
    this.userId = this.authService.getUserId();
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        //console.log(e);
        this.currentPage = e.urlAfterRedirects;
      }
    });
  }

  ngOnInit() {
    this.navElement = null;
    this.isDrawerOpen = false;
    if(this.authService.IsLoggedIn){
      this.isLogedIn = true;
    }
  }

  onLogoutClick() {
    // this.isLoggedIn = false;
    // this.authService.resetTokens();
  }

  redirectToHome() {
    this.router.navigate([''])
  }

}
