import { Component, OnInit, Inject } from '@angular/core';
import { CoreService } from 'src/app/helpers/services/core.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'loyaltic-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  UserName = '';
  adminLock = true;

  constructor(@Inject('ImagePath') public ImagePath,
    private coreService: CoreService, private toast: ToastrService,
    public translate: TranslateService) { }

  ngOnInit() {
    if (window.location.pathname === "/admin") {
      this.adminLock = false;
    }
  }

  onSubmit(form) {
    this.coreService.signUp(this.UserName).then((response: any) => {
      form.submitted = false;
      this.UserName = '';
      const messageKey = response.messageKey;
      this.toast.success(this.translate.instant('' + messageKey), this.translate.instant('success'));
    });
  }

}
