import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { LoyalticInterceptor } from './helpers/interceptor/interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Loyaltic } from './helpers/constants/api-url.constants';
import { Adal8Service, Adal8HTTPService } from 'adal-angular8';
import { SafeHtmlPipe } from './helpers/pipe/safehtml';
import { SharedModule } from './modules/core/components/shared/shared.module';
import { FooterComponent } from './SharedComponents/footer/footer.component';
import { HeaderComponent } from './SharedComponents/header/header.component';
import { LayoutComponent } from './SharedComponents/layout/layout.component';
import { ModalDialogComponent } from './SharedComponents/modal-dialog/modal-dialog.component';
import { ComingSoonComponent } from './modules/core/components/coming-soon/coming-soon.component';
import { FooterComingsoonComponent } from './modules/core/components/coming-soon/footer-comingsoon/footer-comingsoon.component';
import { HeaderComingsoonComponent } from './modules/core/components/coming-soon/header-comingsoon/header-comingsoon.component';
import { AdminComponent } from './SharedComponents/admin/admin.component';

export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }
  getTranslation(lang: string): Observable<any> {
    return this.http.get(environment.baseUrl + Loyaltic.API.LocalizationByCulture + '?culture=' + lang).pipe(map(
      (res: any) => {
        const translation = {};
        res.forEach(element => {
          translation[element.key] = element.text;
        });
        return translation;
      })
    );
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    ModalDialogComponent,
    ComingSoonComponent,
    FooterComingsoonComponent,
    HeaderComingsoonComponent,
    AdminComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    HttpClientModule,
    FormsModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.doubleBounce,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.52)',
      // backdropBorderRadius: '4px',
      primaryColour: '#fff',
      secondaryColour: '#fff',
      tertiaryColour: '#fff'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoyalticInterceptor, multi: true },
    { provide: 'ImagePath', useValue: environment.ImagePath },
    BsModalRef,
    { provide: 'googleTagManagerId', useValue: '' },
    CookieService,
    Adal8Service,
    {
      provide: Adal8HTTPService,
      useFactory: Adal8HTTPService.factory,
      deps: [HttpClient, Adal8Service]
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
