import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  private userId: string;
  private tokenTime: string;

  constructor(public translate: TranslateService) {
    this.token = this.getToken();
    this.userId = this.getUserId();
    this.tokenTime = this.getTokenTime();
  }

  public getToken(): string {
    if (localStorage.getItem('id_token') != null) {
      return localStorage.getItem('id_token');
    }
    return null;
  }

  public getUserType() {
    if (localStorage.getItem('userType') != null) {
      return localStorage.getItem('userType');
    }
    return null;
  }

  public IsLoggedIn(): boolean {
    if (localStorage.getItem('id_token') != null) {
      return true;
    }
    return false;
  }

  public setToken(token, id) {
    this.token = token;
    this.userId = id;
    localStorage.setItem('id_token', token);
    localStorage.setItem('id', id);
    localStorage.setItem('time', String(new Date()));
  }

  public getUserId() {
    if (localStorage.getItem('id') != null) {
      return localStorage.getItem('id');
    }
    return null;
  }

  public getTokenTime() {
    if (localStorage.getItem('time') != null) {
      return localStorage.getItem('time');
    }
    return null;
  }

  resetTokens() {
    this.token = null;
    this.userId = null;
    this.tokenTime = null;
    localStorage.clear();
  }
}
