export const environment = {
  production: false,
  //baseUrl: 'https://localhost:44394/',
  baseUrl: 'https://swekitchenelx24api.azurewebsites.net/',
  langJsonPath: '/assets',
  ImagePath: '/assets/images',
  googleAnalytics: {
    domain: 'none',
    trackingId: 'UA-160739827-10'
  },
  authConfig: {
    instance: 'https://login.microsoftonline.com/',
    tenant: 'loyaltic.com',
    tenentId: 'd86b49fe-9dda-48c2-80cd-d048429368f3',
    clientId: '35a290fb-c31e-469d-bb10-9c163c9be65a'
  }
};
