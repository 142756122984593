import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LocalizationService } from 'src/app/helpers/services/localization.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'loyaltic-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent implements OnInit {
  @ViewChild('translation', { static: false }) translationRef: any;
  public modalRef: BsModalRef;
  popupProperties = { ignoreBackdropClick: true, class: 'lyltc modal-md modal-dialog-centered' };

  page: any;
  key = '';
  value = '';
  isGlobal = false;
  selectedCulture = '';
  list: any = [];
  editedObject: any;
  allTranslations: any;
  culture= '';


  constructor(private translate: TranslateService, private localizationService: LocalizationService, private modalService: BsModalService) {
    this.page = this.localizationService.currentPage;
    this.culture = translate.getDefaultLang();
      
      this.translate.onDefaultLangChange 
      .subscribe((event: DefaultLangChangeEvent) => {
        this.translate.use(event.lang);
        this.culture = event.lang      
      });
  }

  ngOnInit(): void {
    // this.page = 'home';
    this.selectedCulture = this.culture;
    this.get();

    this.translate.onDefaultLangChange.subscribe(res => {
      this.selectedCulture = this.culture;
      this.get();
    });
  }

  get(isReload = false) {
    this.editedObject = undefined;
    this.localizationService.get(this.page, this.selectedCulture).then(res => {
      this.allTranslations = this.list = res;
      if (isReload) {
        this.reloadTheTranslations();
      }
    });
  }


  post(form) {
    if (this.editedObject) {
      const body = {
        LocalizationId: this.editedObject.id,
        Value: this.value,
      };

      this.localizationService.put(body).then(res => {
        this.translate.setDefaultLang(this.selectedCulture);
        this.key = '';
        this.value = '';
        form.submitted = false;
        this.get(true);
        this.modalRef.hide();
      });
    } else {
      const body = {
        Key: this.key,
        Value: this.value,
        Page: this.page,
        IsGlobal: this.isGlobal
      };

      this.localizationService.post(body).then(res => {
        this.key = '';
        this.value = '';
        form.submitted = false;
        this.get(true);
        this.modalRef.hide();
      });
    }
  }

  editTranslation(obj) {
    this.value = obj.text;
    this.key = obj.key;
    this.editedObject = obj;
    this.modalRef = this.modalService.show(this.translationRef, this.popupProperties);
  }

  add() {
    this.editedObject = null;
    this.key = '';
    this.value = '';
    this.isGlobal = false;
    this.modalRef = this.modalService.show(this.translationRef, this.popupProperties);
  }

  onSearchInput(value) {
    this.list = this.allTranslations.filter(t => t.key.indexOf(value) > -1);
  }


  reloadTheTranslations() {
    this.localizationService.getTranslations(this.selectedCulture).then(response => {
      this.translate.setTranslation(this.selectedCulture, response, true);
      this.translate.use(this.selectedCulture);
    });
  }

}
