import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Adal8Service } from 'adal-angular8';

@Component({
  selector: 'loyaltic-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private adalService: Adal8Service, private router: Router) { }

  ngOnInit() {
    this.adalService.getUser().subscribe(res => {
      if (!res) {
        this.adalService.login();
      } else {
        this.router.navigate(['/']);
      }
    });
  }

}
