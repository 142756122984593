import { Injectable } from '@angular/core';
declare let gtag: Function;
//declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class GaService {

  
  public emitEvent(eventAction: string,screenName: string) {
    gtag('event', eventAction, {
      'app_name': 'SWEAEGCB23',
      'screen_name': screenName
    });
  }

  // public emitEvent(eventCategory: string,
  //   eventAction: string,
  //   eventLabel: string = null,
  //   eventValue: number = null) {
  //     gtag('event', eventAction, {
  //       'event_category': eventCategory,
  //       'event_label': eventLabel== null ? eventAction : eventLabel,
  //       'value': eventValue
  //     });
    
  //   fbq('track', 'event', { eventCategory: eventCategory, eventLabel: eventLabel== null ? eventAction : eventLabel });
  // }
}
