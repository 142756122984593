import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Loyaltic } from '../constants/api-url.constants';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service';
import { Adal8Service } from 'adal-angular8';
import { Router } from '@angular/router';

@Injectable()
export class LoyalticInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,  private router: Router, private loader: LoaderService, private adalService: Adal8Service) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf(environment.baseUrl + Loyaltic.API.LoginWithUploadReceipt) === -1 &&
            request.url.indexOf(environment.baseUrl + Loyaltic.API.Upload) === -1 &&
            request.url.indexOf(environment.baseUrl + Loyaltic.API.RegUploadWithRealTimeScanning) === -1) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        }

        const token = this.authService.getToken();
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
        }
        request = request.clone({ headers: request.headers.set('ApiKey', `49D7253EC1384D1339CE24786C137`) });

        if (this.loader.isShowLoader) {
            this.loader.show();
        }
        return next.handle(request).pipe(
            map(resp => {
                if (resp instanceof HttpResponse) {
                    this.loader.isShowLoader = true;
                    this.loader.hide();
                }
                return resp;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403) {
                  this.authService.resetTokens();
                  this.router.navigate(['home']);
                }
                this.loader.hide();
                return throwError(error.status);
            })
        );
    }
}
